<template>
  <div class="bottom">
    <div class="tile_wrap">
      <div class="img mr_5">
        <img src="@/assets/img/index/pin.png" alt="" />
      </div>
      <span>{{data[0].title}}</span>
    </div>
    <div class="main">
      <div class="top_img">
        <div class="img" v-for="item in data[0].banner" :key="item.id">
          <img :src="item.logo" alt="" @click="click(item)" />
        </div>
      </div>

      <!-- <div class="wrapper"> -->
        <!-- <div class="img" v-for="item in data[0].brands" :key="item.img">
          <img :src="item.logo" alt="" @click="toGoodsData(item)" />
        </div> -->
        <!-- <div class="flex_c_c c_p img" style="height: 100px; width: 100px" @click="handleMore"><i class="el-icon-refresh-left"></i>换一批</div> -->

        <swiper class="wrapper" :options="swiperOption">
          <swiper-slide class="wra_img" v-for="item in data[0].brands" :key="item.img">
            <img :src="item.logo" alt="" @click="toGoodsData(item)" />
          </swiper-slide>
        </swiper>



      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
// import { DeepClone } from "@/utils/tool";
export default {
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
      brand_adv: [],
      brand_swiper: [],
      animateUp: false,
      timer: null,
      swiperOption: {
        loop: true,
        slidesPerView: "auto",
        autoplay: {
          disableOnInteraction:false
        },
      },
    };
  },
  mounted() {
    // this.$api("home.getBrand").then((res) => {
    //   this.brand_adv = res.data.brand_adv;
    //   this.brand_swiper = res.data.brand_swiper;
    //   // if (this.brand_swiper.length >= 10) {
    //   //   this.timer = setInterval(this.scrollAnimate, 2000);
    //   // }
    // });
  },
  methods: {
    click(e) {
      // console.log("e----", e);
      // this.$api("home.getClick", { adv_id: e.id });
      handleJump(e.pcJump);
    },
    toGoodsData(data) {
      // console.log("品牌id---", data.brand_id);
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        path: "/index/allGoods",
        query: { brand_id: data.brand_id },
      });
    },
    scrollAnimate() {
      this.animateUp = true;
      // setTimeout(() => {
      //   this.brand_swiper.push(this.brand_swiper[0]);
      //   this.brand_swiper.shift();
      //   this.animateUp = false;
      // }, 500);
    },
    // handleMore() {
    //   let arr = DeepClone(this.brand_swiper);
    //   let arr1 = this.shuffle(arr);
    //   this.brand_swiper = arr1;
    // },
    shuffle(array) {
      var j, x, i;
      for (i = array.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = array[i - 1];
        array[i - 1] = array[j];
        array[j] = x;
      }
      return array;
    },
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateX(-110px);
}
.bottom {
  width: 100%;
  height: 405px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  .tile_wrap {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    .img {
      width: 29px;
      height: 29px;
      

    }
    span {
      font-size: 22px;
      font-family: CKTKingKong;
      font-weight: bold;
      color: #f14a49;
    }
  }
  .main {
    padding: 20px;
    padding-top: 0;
    .top_img {
      display: flex;
      justify-content: space-between;
      .img {
        width: 380px;
        height: 190px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .wrapper {
      width: 100%;
      display: flex;
      margin-top: 20px;
      // animation: slide 30s linear infinite;
      .wra_img {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        margin-right: 6px;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.6);
        }
      }
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    @keyframes slide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-40%); /* 根据盒子宽度和数量调整 */
      }
    }
  }
}
</style>
