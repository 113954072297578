<template>
  <div>
    <transition name="el-fade-in-linear">
      <div class="floor_nav" v-if="show">
        <div
          v-for="(item, i) in listFloor"
          :key="i"
          class="item"
          :class="[current == i ? 'active' : '']"
          @click="handleGo(item.id, i)"
        >
          {{ item.title }}
        </div>
        <div class="back_top" @click="backToTop">
          <div class="img">
            <i class="el-icon-arrow-up"></i>
          </div>
          <span>顶部</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    list: {
      handler(newVal) {
        // console.log(oldVal);
        this.listFloor = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      show: false,
      current: 0,
      listFloor: [],
      isClick: false,
      timeType:null
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 480) {
        this.show = true;
      } else {
        this.show = false;
      }

      let offsetTopArr = [];
      this.list.forEach((v) => {
        // console.log(v.id);
        offsetTopArr.push(document.getElementById(v.id).offsetTop);
      });

      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= (offsetTopArr[n] + 50)) {
          navIndex = n;
        }
      }

      if (!this.isClick) {
        this.current = navIndex;
      }
    },
    handleGo(id,i) {
      this.isClick = true;
      clearTimeout(this.timeType)
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      this.current = i;
      this.timeType = setTimeout(() => {
        this.isClick = false;
      },1200)
      
    },
    backToTop() {
      document.getElementById("index").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.floor_nav {
  width: 58px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #eeeeee;
  border-radius: 8px;
  position: fixed;
  top: 25%;
  left: 12%;
  z-index: 99;
  box-sizing: border-box;
  color: #333333;
  line-height: 18px;
  overflow: hidden;
  .item {
    padding: 10px 10px;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    letter-spacing: 2px;
    &.active {
      background-color: #12288a;
      color: #fff;
    }
  }
  .back_top {
    font-weight: bold;
    color: @themeColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .img {
    font-size: 16px;
  }
}
</style>
