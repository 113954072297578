<template>
  <!-- <div class="five" :style="{ backgroundImage: `url(${data.floor[0].bj_img})` }"> -->
  <div>
    <div class="five" v-for="item in data" :key="item.id">
      <div class="tile_wrap">
        <div class="left">
          <img v-if="item.icon_logo" class="img" :src="item.icon_logo" alt="" />
          <div v-if="!item.icon_logo" class="main-title" style="font-size: 22px; color: #12288a; font-weight: bold; height: 29px; display: flex; align-items: flex-end; margin-right: 6px">
            {{ item.title }}
          </div>
          <div v-if="!item.icon_logo" class="subtitle-style" style="font-size: 16px; color: #000000; height: 29px; display: flex; align-items: flex-end">{{ item.subtitle }}</div>
        </div>
        <!-- <div class="right c_p" @click="handleJump(data.floor[0].jump)"> -->
        <div class="right" @click="handleMore(item.banner[0].pcJump)">
          <span style="cursor: pointer">更多</span>
          <div class="more">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="main" v-if="item.banner[0]">
        <div class="left" @click="handleJump(item.banner[0].pcJump)">
          <img :src="item.banner[0].logo" alt="" />
        </div>
        <div class="right">
          <div class="item" v-for="k in item.goods.slice(0, 8)" :key="k.id" @click="handleDetail(k.id)">
            <div class="img">
              <img :src="k.logo" alt="" />
            </div>
            <p class="pr">￥{{ k.price }}</p>
            <p class="til shenglue_1">{{ k.title }}</p>
            <p class="shenglue_1 f12">{{ k.sku }}</p>
          </div>
        </div>
      </div>
      <div class="main" v-else>
        <!-- <div class="left" @click="handleJump(item.banner[0].pcJump)">
          <img :src="item.banner[0].logo" alt="" />
        </div> -->
        <div class="right">
          <div class="item" v-for="k in item.goods" :key="k.id" @click="handleDetail(k.id)">
            <div class="img">
              <img :src="k.logo" alt="" />
            </div>
            <p class="pr">￥{{ k.price }}</p>
            <p class="til shenglue_1">{{ k.title }}</p>
            <p class="shenglue_1 f12">{{ k.sku }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
export default {
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  created() {
    // console.log("样式7接收数据----", this.data);
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      // console.log("首页---", item);
      // this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      if (item.jump.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
      }
      handleJump(item.jump);
    },
  },
};
</script>

<style lang="less" scoped>
.five {
  width: 100%;
  height: 598px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  background-size: cover;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 120px !important;
        height: 29px !important;
      }
    }
    .right {
      display: flex;
      align-items: center;

      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .main {
    padding: 20px;
    padding-top: 0;
    display: flex;
    .left {
      width: 365px;
      height: 510px;
      border-radius: 4px;
      cursor: pointer;
      .main-title {
        height: 100%;
        height: 29px;
        // text-align: end;
        font-size: 30px;
        font-weight: bold;
        color: #12288a;
        display: flex;
        align-items: flex-end;
        background-color: bisque;
      }
      .subtitle-style {
        height: 100%;
        height: 29px;
        // text-align: end;
        font-size: 14px;
        color: #515a6e;
        display: flex;
        align-items: flex-end;
        // background-color: aquamarine;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      flex: 1;
      margin-left: 9px;
      .item {
        width: 188px;
        height: 249px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        &:hover {
          box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
          border: 1px solid #fff;
        }
        .img {
          width: 150px;
          height: 150px;
          background: #eeeeee;
          border-radius: 4px;
        }
        .til {
          font-weight: bold;
          color: #333333;
          margin: 4px 0;
        }
        .pr {
          font-size: 16px;
          font-weight: bold;
          margin-top: 4px;
          color: @priceRed;
        }
      }
    }
  }
}
</style>
