<template>
  <div class="liveColumn">
    <div class="title">
      <div class="flex_row_aic font_b f22 f14">
        <div class="iconBox mr_5">
          <img src="@/assets/img/index/pin.png" alt="" />
        </div>
        <span>{{ data[0].title }}</span>
      </div>
      <div class="right c_p" @click="jumpUrl(data[0].banner[0].pcJump)">
        <span>查看更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_left">
        <swiper :options="swiperOption">
          <swiper-slide v-for="item in data[0].goods" :key="item.id"
            ><div class="goods_item" @click="jumpUrl(data[0].banner[0].pcJump)">
              <div class="img_box">
                <img :src="item.logo" alt="" />
              </div>
              <div class="text_box">
                <p class="shenglue_1">{{ item.title }}</p>
                <p class="shenglue_1">{{ item.sku }}</p>
                <p class="shenglue_1">{{ item.scqy }}</p>
                <p class="flex_row_aic">
                  <span class="font_b f14 cf42 mr_5">直播间查看</span>
                  <span>￥</span>
                  <span class="text_dashed">{{ item.line_price }}</span>
                  <span class="zb_tag">直播价</span>
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="content_right">
        <el-carousel height="276px">
          <el-carousel-item v-for="(item,i) in data[0].banner" :key="i">
            <img :src="item.logo" @click="jumpUrl(item.pcJump)" style="border-radius: 8px" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
export default {
  props: ["data"],
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 'auto',
        autoplay: {
          disableOnInteraction:false
        },
      },
    };
  },
  created() {
    
  },
  methods: {
    jumpUrl(data) {
      handleJump(data)
    }
  },
};
</script>

<style lang="less" scoped>
.liveColumn {
  width: 100%;
  // background: url("https://static.scytyy.net/test/ms_bj.jpg")
  //   no-repeat 50%;
  background: linear-gradient(to bottom, #fff 0%, rgba(253,113,113,0.3) 50%);
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 20px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 20px;
      font-weight: 700;
    }
    a {
      color: #999;
      text-decoration: none;
    }
    .iconBox {
      width: 29px;
      height: 29px;
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        line-height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 276px;
    margin-top: 10px;
    .content_left {
      width: 60%;
      height: 100%;
      display: flex;
      background-color: #fff;
      justify-content: space-around;
      border-radius: 8px;
      .goods_item {
        width: 180px;
        cursor: pointer;
        .img_box {
          width: 170px;
          height: 170px;
          margin: 0 auto;
        }
        .text_box {
          width: 100%;
          padding: 0 8px;
          p {
            margin-bottom: 2px;
            &:nth-child(1) {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .zb_tag {
            font-size: 12px;
            padding: 2px 3px;
            background-color: #3f50ea;
            margin-left: 5px;
            border-radius: 4px;
            color: #fff;
          }
        }
      }
    }
    .content_right {
      width: 37%;
      height: 276px;
      cursor: pointer;
      // background-color: #afdf95;
      border-radius: 8px;
      overflow: hidden;
    }
    .swiper-slide {
      width: 180px;
      margin: 0 24px;
    }
  }
}
</style>