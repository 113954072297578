<template>
  <div class="inner">
    <div class="tile_wrap">
      <div class="left">
        <div class="img">
          <img src="@/assets/img/index/miao.png" alt="" />
        </div>
        <span class="title">{{ seckillData.title }}</span>
        <div class="time_box">
          <div class="session">{{handleTime(seckill.qssjs)}}场</div>
          <div class="jl_time">
            <span>{{ timeText }}</span
            >还剩<span style="margin-left: 2px">{{ djs }}</span>
          </div>
        </div>
        <!-- <div class="time-down">{{ djs }}</div> -->
      </div>
      <div class="right c_p" @click="handleMore(seckills)">
        <span>查看更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="swiper-container container2">
      <div class="item_wrap swiper-wrapper">
        <div
          class="item swiper-slide"
          v-for="(item, i) in seckillData.goods.slice(0, 5)"
          :key="i"
          @click="handleMore(item.id)"
        >
          <div class="img">
            <img :src="item.logo" alt="" />
          </div>
          <div class="text_box">
            <p class="f16 font_b shenglue_1">{{ item.title }}</p>
            <p class="f14 mt_5 shenglue_1">{{ item.sku }}</p>
            <div class="flex_sp_c mt_10">
              <el-progress
                color="#f13a3a"
                :class="item.kc_strx == 0 ? 'actice_yqg' : ''"
                :stroke-width="8"
                :show-text="false"
                :percentage="setPercent(item)"
                status="exception"
              ></el-progress>
              <span class="f12 white-space cf14">{{
                "已抢" + (100 - setPercent(item)) + "%"
              }}</span>
            </div>
            <div class="ms_btn">
              <span class="f14">秒杀价:</span>
              <span class="f27 font_b" v-if="item.show_cart === '101'">{{
                item.price
              }}</span>
              <span class="f27 font_b" v-else>???</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { handleJump } from "@/utils";
export default {
  // props: {
  //   seckill: {
  //     type: Object,
  //     default: () => {},
  //   },
  //   coupon: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  props: ["seckills"],
  watch: {
    seckills: {
      handler(newVal) {
        // console.log(oldVal);
        this.seckillData = newVal;
        this.seckill.jssjs = this.seckillData.countdown_time; //结束时间
        this.seckill.qssjs = this.seckillData.start_time; //开始时间
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      handleJump,
      seckillData: {},
      countdown_time: "",
      djs: "",
      seckill_str: "",
      timeText: "距开始",
      btnText: "即将开始",
      seckill: {}, //当前显示的时间数据
    };
  },
  // mounted() {
  //   new Swiper(".container2", {
  //     loop: true,
  //     slidesPerView: this.coupon?.list.length > 0 ? 4 : 6, // 一组三个
  //     spaceBetween: 10, // 间隔
  //     autoplay: {
  //       delay: 5000, // 每次轮播间隔的ms数，默认3000ms
  //       stopOnLastSlide: false, // 播放完最后一张图片后是否停留在最后一张图片上，停止继续轮播。默认false
  //     },
  //   });
  // },
  created() {},
  mounted() {
    setInterval(() => {
      this.InitTime();
    }, 1000);
  },
  methods: {
    InitTime() {
      var currentDate = new Date().getTime();
      var remainingTime = "";
      if (this.seckill.qssjs * 1000 > currentDate) {
        this.timeText = "距开始";
        remainingTime = this.seckill.qssjs * 1000 - currentDate;
      } else if (this.seckill.jssjs * 1000 >= currentDate) {
        this.timeText = "距结束";
        remainingTime = this.seckill.jssjs * 1000 - currentDate;
      } else {
        this.timeText = "已结束";
      }

      // 将剩余时间转换为天、小时、分钟和秒
      var hours = Math.floor(remainingTime / (1000 * 60 * 60));
      var minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      // 在个位数前加上前导零
      hours = this.padZero(hours);
      minutes = this.padZero(minutes);
      seconds = this.padZero(seconds);
      this.djs = hours + ":" + minutes + ":" + seconds;
      // 如果倒计时结束，则显示提示信息
      if (remainingTime <= 0) {
        clearInterval(this.interval);
        this.djs = "00:00:00";
        this.timeText = "已结束";
      }
    },
    // 处理时间
    handleTime(time) {
      var date = new Date(time * 1000);
      var d = date.getHours() ;
      var m = date.getMinutes();
      d = this.padZero(d);
      m = this.padZero(m);
      return d +':' + m;
    },
    // 计算剩余商品数量百分比
    setPercent(item) {
      if (item.hdinfo.sale_num == 0) {
        return 100;
      }
      if (item.hdinfo.sale_num >= item.hdinfo.xgkc) {
        return 0;
      }
      return Math.round((item.hdinfo.sale_num / item.hdinfo.xgkc) * 100);
    },
    // 处理倒计时的个位数
    padZero(num) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      // let firstTab = this.$store.state.pageName;
      // console.log("面包屑---", firstTab);
      // this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      // if (item.url.url == "/index/allGoods") {
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        path: "/index/skill",
        query: { area_id: item.id },
      });
      // }
      // handleJump(item.url);
    },
  },
};
</script>

<style lang="less" scoped>
.inner {
  width: 1200px;
  // width: 1200px;
  // width:100%;
  height: 384px;
  background: #fff;
  border-radius: 8px;
  margin-top: 15px;
  padding: 0 16px;
  background: url("https://static.scytyy.net/test/ms_bj.jpg") no-repeat 50%;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 29px;
        height: 29px;
        margin-right: 5px;
      }
      .title {
        font-size: 22px;
        font-weight: bold;
        color: #e63525;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        line-height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .item_wrap {
    -webkit-column-gap: 17px;
    -moz-column-gap: 17px;
    column-gap: 17px;
    .item {
      width: 220px;
      height: 318px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      .img {
        width: 166px;
        height: 166px;
        background: #eeeeee;
        border-radius: 4px;
        margin: 10px auto 0;
      }
      .text_box {
        color: #000;
        padding: 0 16px;
        margin-top: 12px;
        .ms_btn {
          width: 180px;
          height: 38px;
          box-sizing: border-box;
          padding: 0 12px;
          display: flex;
          align-items: baseline;
          color: #e63525;
          border-radius: 38px;
          margin-top: 10px;
          background: url("https://static.scytyy.net/test/ms_btn.png") no-repeat;
          span {
            line-height: 40px;
          }
        }
      }
    }
  }
  .el-progress-bar__outer {
    border: 1px solid #f7e3e4;
    background-color: #fff !important;
  }
  .actice_yqg .el-progress-bar__outer {
    background-color: rgb(235, 238, 245) !important;
    border: none;
  }
  .el-progress {
    width: 132px;
  }
  .time_box {
    display: flex;
    background-color: #ffe7e9;
    border-radius: 30px;
    margin-left: 12px;
    .session {
      padding: 3px 15px;
      background-color: #e63525;
      border-radius: 30px;
      color: #fff;
      font-weight: 500;
      font-size: 15px;
    }
    .jl_time {
      padding: 3px 18px;
      border-radius: 30px;
    }
  }
}
</style>
