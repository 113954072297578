<template>
  <div class="banner_wrap">
    <div class="banner_box">
      <div class="swiper">
        <el-carousel ref="carouse" indicator-position="none" height="480px" :autoplay="true" :interval="5000" @change="change">
          <el-carousel-item :label="item.title" v-for="(item, index) in bgBanner.banner" :key="index">
            <img class="swiper_img" @click="BannerJump()" :src="item.logo" alt="" ref="underlyingBox"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="banner_container">
        <div class="content c_p">
          <goodsCate />
          <div @click="BannerJump()" class="flex_1 flex_column" v-if="bgBanner.banner2" style="justify-content: flex-end;">
            <!-- <div @click="click" class="flex_1"></div>
            <div @click="click" class="flex_1"></div> -->
            <div class="flex_row" style="height: 118px;">
              <div class="img_box" v-for="item in bgBanner.banner2" :key="item.id" @click="clickarr(item)">
                <img :src="item.logo" alt="" />
              </div>
            </div>
          </div>
          <info :infoData="bgBanner.notice" :setting="setting" />
        </div>
      </div>
    </div>
    <div class="switch_box">
        <div class="switch_item" @mouseenter="onTouchStart(i)" :class="{active:banner_index===i}" v-for="(item, i) in bgBanner.banner" :key="i">
          {{ item.title }}
        </div>
    </div>
  </div>
</template>

<script>
import goodsCate from "./goodsCate.vue";
import info from "./info.vue";
import { handleJump } from "@/utils";
export default {
  props: ["bgBanner"],
  components: {
    goodsCate,
    info,
  },
  data() {
    return {
      swiperList: this.$store.state.homeComData.banner,
      threeList: this.$store.state.homeComData.home_adv2,
      setting: this.$store.state.homeComData.setting,
      banner_index: 0,
    };
  },
  created() {
    // console.log("666---------------------------------", this.$store.state.homeComData.setting);
    // this.$api("home.getNavInfo").then((res) => {
    //   this.swiperList = res.data.banner;
    //   this.threeList = res.data.home_adv2;
    // });
  },
  methods: {
    BannerJump() {
      let jump = this.bgBanner.banner[this.banner_index].pcJump;
      let str = jump.value.substr(0, 4);
      if (str !== "http") {
        handleJump(jump);
      } else {
        window.open(jump.value, "_blank");
      }
    },
    clickarr(e) {
      // console.log("e---11----", e);
      // this.$api("home.getClick", { adv_id: e.id });
      let jump = e.pcJump;
      // console.log("666----", jump);
      let str = jump.value.substr(0, 4);
      if (str !== "http") {
        handleJump(jump);
      } else {
        return;
      }
      // handleJump(e.pcJump);
    },
    test() {
      // console.log(666);
    },
    change(e) {
      this.banner_index = e;
    },
    onTouchStart(index) {
      // 处理触摸开始事件
      this.banner_index = index;
      this.$refs.carouse.setActiveItem(index);
    }
  },
};
</script>

<style lang="less" scoped>
.banner_wrap {
  position: relative;
  width: 100%;
  height: 480px;
}
.banner_box {
  position: relative;
  z-index: 10;
  .swiper {
    .swiper_img {
      width: 100% !important;
      height: 480px !important;
      object-fit: cover;
    }
  }
}
.banner_container {
  position: absolute;
  z-index: 100;
  top: 0;
  width: 100%;
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .img_box {
      display: flex;
      margin-left: 5px;
      &:last-child {
        margin-right: 5px;
      }
      img {
        cursor: pointer;
        width: 270px !important;
        height: 118px !important;
      }
    }
  }
}
.switch_box{
  width: 810px;
  padding-left: 5px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1px;
  z-index: 10;
  &>.switch_item{
    width: 110px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    background-color: rgba(149, 150, 151,0.8);
    transition: all 0.25s;
  //   &:hover{
  //   background-color: rgba(18, 40, 138, 0.8);
  //   height: 30px;
  //   border-radius: 6px 6px 0 0;
  // }
  }
  &>.active{
    background-color: rgba(91, 91, 91,0.8);
    // background-color: rgba(203, 85, 236, 0.9);
    height: 28px;
    border-radius: 6px 6px 0 0;
  }
}
</style>
